import { TextField } from "@material-ui/core";

// Redux
import { SEARCH_PRODUCTS } from "../../redux/types/product";
import { useDispatch, useSelector } from "react-redux";

const SearchBar = () => {
  const dispatch = useDispatch();
  const { searchTerm } = useSelector((state) => state.product);
  return (
    <TextField
      type="text"
      name="itemName"
      placeholder="Search by Item name or barcode"
      fullWidth
      my={2}
      value={searchTerm}
      variant="outlined"
      onChange={(event) =>
        dispatch({
          type: SEARCH_PRODUCTS,
          payload: event.target.value,
        })
      }
    />
  );
};

export default SearchBar;
