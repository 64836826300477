import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";

// @material-ui/lab components
import { Skeleton } from "@material-ui/lab/";

// redux
import { useDispatch, useSelector } from "react-redux";
import {
  getProducts,
  addProductToCart,
} from "../../redux/actions/productActions";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function SearchResults() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [selectedIndex] = React.useState(1);

  const { loading, searchTerm, products, cartProducts } = useSelector(
    (state) => state.product
  );

  useEffect(() => {
    getProducts(dispatch, searchTerm);
  }, [dispatch, searchTerm]);

  return (
    <div className={classes.root}>
      {loading ? (
        [...Array(4)].map((x, index) => {
          return (
            <div key={index}>
              <Skeleton animation="wave" />
            </div>
          );
        })
      ) : (
        <List component="nav" aria-label="main mailbox folders">
          {products.map((product, index) => {
            return (
              <div key={product.id}>
                <ListItem
                  button
                  selected={selectedIndex === index % 2}
                  onClick={(event) =>
                    addProductToCart(dispatch, product, cartProducts)
                  }
                >
                  <ListItemText style={{ fontSize: "10px" }}>
                    {product.name} ({product.category.name}) - KES{" "}
                    {product.sellingPrice}
                  </ListItemText>
                </ListItem>
                <Divider />
              </div>
            );
          })}
        </List>
      )}
    </div>
  );
}
