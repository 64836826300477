import { Grid, ListItem as MuiListItem } from "@material-ui/core";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

const ListItemNotButton = styled(MuiListItem)(spacing);

const DiscountAmountWrapper = styled.div`
  float: right;
`;

const OrderTotal = ({ orderTotal }) => {
  return (
    <ListItemNotButton>
      <Grid container>
        <Grid item xs={12} lg={6}>
          Order Total:
        </Grid>
        <Grid item xs={12} lg={6}>
          <DiscountAmountWrapper>KES {orderTotal}</DiscountAmountWrapper>
        </Grid>
      </Grid>
    </ListItemNotButton>
  );
};

export default OrderTotal;
