import {
  LOADING_SALES_TODAY,
  SET_SALES_TODAY,
  LOADING_SALES_THIS_MONTH,
  SET_SALES_THIS_MONTH,
  LOADING_SALES_THIS_YEAR,
  SET_SALES_THIS_YEAR,
  LOADING_OVERALL_SALES,
  SET_OVERALL_SALES,
} from "../types/sales";

const initialState = {
  salesTodaySummary: {
    loading: false,
    sales: 0,
  },
  salesThisMonthSummary: {
    loading: false,
    sales: 0,
  },
  salesThisYearSummary: {
    loading: false,
    sales: 0,
  },
  totalSalesSummary: {
    loading: false,
    sales: 0,
  },
};

const salesReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING_SALES_TODAY:
      return {
        ...state,
        salesTodaySummary: {
          ...state.salesTodaySummary,
          loading: true,
        },
      };
    case SET_SALES_TODAY:
      return {
        ...state,
        salesTodaySummary: {
          ...state.salesTodaySummary,
          loading: false,
          sales: action.payload,
        },
      };
    case LOADING_SALES_THIS_MONTH:
      return {
        ...state,
        salesThisMonthSummary: {
          ...state.salesThisMonthSummary,
          loading: true,
        },
      };
    case SET_SALES_THIS_MONTH:
      return {
        ...state,
        salesThisMonthSummary: {
          ...state.salesThisMonthSummary,
          loading: false,
          sales: action.payload,
        },
      };
    case LOADING_SALES_THIS_YEAR:
      return {
        ...state,
        salesThisYearSummary: {
          ...state.salesThisYearSummary,
          loading: true,
        },
      };
    case SET_SALES_THIS_YEAR:
      return {
        ...state,
        salesThisYearSummary: {
          ...state.salesThisYearSummary,
          loading: false,
          sales: action.payload,
        },
      };
    case LOADING_OVERALL_SALES:
      return {
        ...state,
        totalSalesSummary: {
          ...state.totalSalesSummary,
          loading: true,
        },
      };
    case SET_OVERALL_SALES:
      return {
        ...state,
        totalSalesSummary: {
          ...state.totalSalesSummary,
          loading: false,
          sales: action.payload,
        },
      };
    default:
      return state;
  }
};

export default salesReducer;
