import { SUBMITTING_ORDER, SUBMITTED_ORDER } from "../types/order";

import axios from "axios";

const submitOrder = async (dispatch, order) => {
  dispatch({
    type: SUBMITTING_ORDER,
  });

  try {
    const response = await axios.post(`orders/new`, order);

    dispatch({
      type: SUBMITTED_ORDER,
      payload: response.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export { submitOrder };
