import React from "react";
import { Grid, ListItem as MuiListItem } from "@material-ui/core";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

const ListItemNotButton = styled(MuiListItem)(spacing);

const DiscountAmountWrapper = styled.div`
  float: right;
`;

const OrderSubTotal = ({ orderTotal, orderDiscount }) => {
  return (
    <ListItemNotButton
      style={{
        backgroundColor: "#f1ffec",
        height: "40px",
        marginTop: "10px",
        marginBottom: "-25px",
      }}
    >
      <Grid container>
        <Grid item xs={12} style={{ fontWeight: "bold" }} lg={6}>
          Sub Total:
        </Grid>
        <Grid item xs={12} lg={6}>
          <DiscountAmountWrapper>
            KES {orderTotal - orderDiscount}
          </DiscountAmountWrapper>
        </Grid>
      </Grid>
    </ListItemNotButton>
  );
};

export default OrderSubTotal;
