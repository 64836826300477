import React from "react";
import styled from "styled-components/macro";

import { Helmet } from "react-helmet-async";

import {
  Grid,
  Divider as MuiDivider,
  Typography as MuiTypography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

import Actions from "../../components/Dashboard/Actions";
import RecentCustomers from "../../components/Dashboard/RecentCustomers";
import SalesAnalytics from "../../components/Dashboard/SalesAnalytics";
import RecentSales from "../../components/Dashboard/RecentSales";
import SalesToday from "../../components/Dashboard/SalesToday";
import SalesThisMonth from "../../components/Dashboard/SalesThisMonth";
import SalesThisYear from "../../components/Dashboard/SalesThisYear";
import TotalSales from "../../components/Dashboard/TotalSales";

const Divider = styled(MuiDivider)(spacing);

const Typography = styled(MuiTypography)(spacing);

function Default() {
  return (
    <React.Fragment>
      <Helmet title="Dashboard" />
      <Grid justify="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="subtitle1">
            Good to have you back, Vincent! We've missed you.{" "}
            <span role="img" aria-label="Waving Hand Sign">
              👋
            </span>
          </Typography>
        </Grid>

        <Grid item>
          <Actions />
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <SalesToday period="today" />
        <SalesThisMonth period="month" />
        <SalesThisYear period="year" />
        <TotalSales period="overall" />
      </Grid>

      <Grid container spacing={6}>
        <Grid item xs={12} lg={12}>
          <SalesAnalytics />
        </Grid>
      </Grid>
      <Grid container spacing={6}>
        <Grid item xs={12} lg={4}>
          <RecentCustomers />
        </Grid>
        <Grid item xs={12} lg={8}>
          <RecentSales />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Default;
