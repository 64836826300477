export const LOADING_PRODUCTS = "LOADING_PRODUCTS";
export const SET_PRODUCTS = "SET_PRODUCTS";
export const SEARCH_PRODUCTS = "SEARCH_PRODUCTS";
export const ADD_TO_CART = "ADD_TO_CART";
export const UPDATE_CART_QUANTITY = "UPDATE_CART_QUANTITY";
export const UPDATE_PRODUCT_DISCOUNT = "UPDATE_PRODUCT_DISCOUNT";
export const UPDATE_ORDER_DISCOUNT = "UPDATE_ORDER_DISCOUNT";
export const UPDATE_DELIVERY_ADDRESS = "UPDATE_DELIVERY_ADDRESS";
export const SET_SHIPPING_AREA = "SET_SHIPPING_AREA";
export const SET_SPECIAL_NOTES = "SET_SPECIAL_NOTES";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
