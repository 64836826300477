import React from "react";
import styled from "styled-components/macro";

import { FormControlLabel, Switch, Typography } from "@material-ui/core";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { CHANGE_SEND_MESSAGE_STATUS } from "../../redux/types/order";

const SendSMSWrapper = styled(FormControlLabel)`
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 50px;
`;

const SendSMSController = () => {
  const { sendSMS } = useSelector((state) => state.order);

  const dispatch = useDispatch();

  return (
    <SendSMSWrapper
      control={
        <Switch
          checked={sendSMS}
          onChange={() =>
            dispatch({
              type: CHANGE_SEND_MESSAGE_STATUS,
              payload: !sendSMS,
            })
          }
        />
      }
      label={
        <Typography style={{ fontSize: "18px" }}>Notify via SMS</Typography>
      }
    />
  );
};

export default SendSMSController;
