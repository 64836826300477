import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components/macro";

// @material-ui/core components
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Link,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Button,
  IconButton,
} from "@material-ui/core";

// @material-ui/icons components
import { Close as CloseIcon } from "@material-ui/icons";

// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateCartQuantity,
  updateProductDiscount,
  removeFromCart,
} from "../../redux/actions/productActions";

const TableHeadWrapper = styled(TableHead)`
  background-color: #f9fbfc;
  color: #000;
`;

const SelectedProductsWrapper = styled.div`
  height: 400px;
  overflow-y: auto;
  width: 100%;
`;

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const SelectedProducts = () => {
  const { cartProducts } = useSelector((state) => state.product);
  const dispatch = useDispatch();

  const [openQuantity, setOpenQuantity] = useState(false);
  const [openDiscount, setOpenDiscount] = useState(false);
  const [currentProduct, setCurrentProduct] = useState({});

  const handleCloseQuantityDialog = () => {
    setOpenQuantity(false);
  };

  const handleCloseDiscountDialog = () => {
    setOpenDiscount(false);
  };

  const classes = useStyles();

  return (
    <>
      <SelectedProductsWrapper>
        <Table>
          <TableHeadWrapper>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Item Name</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Discount</TableCell>
              <TableCell>Total</TableCell>
            </TableRow>
          </TableHeadWrapper>
          <TableBody>
            {cartProducts.map((product) => {
              return (
                <TableRow key={product.id}>
                  <TableCell component="th" scope="row">
                    <IconButton
                      size="small"
                      aria-label="close"
                      style={{ backgroundColor: "#f48fb1", color: "#fff" }}
                      color="inherit"
                      onClick={() => removeFromCart(dispatch, product)}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {product.name}
                  </TableCell>
                  <TableCell>KES {product.sellingPrice}</TableCell>
                  <TableCell>
                    <Link
                      href="#"
                      onClick={(event) => {
                        event.preventDefault();
                        setCurrentProduct(product);
                        setOpenQuantity(true);
                      }}
                    >
                      {product.quantity}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Link
                      href="#"
                      onClick={(event) => {
                        event.preventDefault();
                        setCurrentProduct(product);
                        setOpenDiscount(true);
                      }}
                    >
                      KES {product.discount}
                    </Link>
                  </TableCell>
                  <TableCell>
                    KES{" "}
                    {product.sellingPrice * product.quantity - product.discount}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </SelectedProductsWrapper>

      <Dialog open={openQuantity} onClose={handleCloseQuantityDialog}>
        <DialogContent>
          <form className={classes.container}>
            <FormControl className={classes.formControl}>
              <TextField
                id="standard-number"
                type="number"
                value={currentProduct.quantity}
                placeholder="Enter Quantity"
                style={{ width: "100%" }}
                onChange={(event) =>
                  updateCartQuantity(
                    dispatch,
                    cartProducts,
                    currentProduct,
                    event.target.value
                  )
                }
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{ inputProps: { min: 1 } }}
              />
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseQuantityDialog} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDiscount} onClose={handleCloseDiscountDialog}>
        <DialogContent>
          <form className={classes.container}>
            <FormControl className={classes.formControl}>
              <TextField
                type="number"
                value={currentProduct.discount}
                placeholder="Enter Quantity"
                style={{ width: "100%" }}
                onChange={(event) =>
                  updateProductDiscount(
                    dispatch,
                    cartProducts,
                    currentProduct,
                    event.target.value
                  )
                }
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{ inputProps: { min: 1 } }}
              />
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDiscountDialog} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SelectedProducts;
