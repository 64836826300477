import React from "react";
import styled, { withTheme } from "styled-components/macro";

import {
  Card as MuiCard,
  CardContent,
  CardHeader,
  IconButton,
  Table,
  TableBody,
  TableCell,
  Typography,
  Box,
  TableRow,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

import "../../vendor/roundedBarCharts";

import { MoreVertical } from "react-feather";

const Card = styled(MuiCard)(spacing);

const RecentCustomersWrapper = styled.div`
  height: 340px;
  overflow-y: auto;
  width: 100%;
`;

const RecentCustomers = ({ theme }) => {
  return (
    <Card mb={1}>
      <CardHeader
        action={
          <IconButton aria-label="settings">
            <MoreVertical />
          </IconButton>
        }
        title="Recent Customers"
      />
      <CardContent>
        <RecentCustomersWrapper>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  <Typography variant="body2" component="div" gutterBottom>
                    <Box fontWeight="fontWeightMedium">John Doe</Box>
                    <Box fontWeight="fontWeightRegular">+254725089232</Box>
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  <Typography variant="body2" component="div" gutterBottom>
                    <Box fontWeight="fontWeightMedium">John Doe</Box>
                    <Box fontWeight="fontWeightRegular">+254725089232</Box>
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  <Typography variant="body2" component="div" gutterBottom>
                    <Box fontWeight="fontWeightMedium">John Doe</Box>
                    <Box fontWeight="fontWeightRegular">+254725089232</Box>
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  <Typography variant="body2" component="div" gutterBottom>
                    <Box fontWeight="fontWeightMedium">John Doe</Box>
                    <Box fontWeight="fontWeightRegular">+254725089232</Box>
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  <Typography variant="body2" component="div" gutterBottom>
                    <Box fontWeight="fontWeightMedium">John Doe</Box>
                    <Box fontWeight="fontWeightRegular">+254725089232</Box>
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  <Typography variant="body2" component="div" gutterBottom>
                    <Box fontWeight="fontWeightMedium">John Doe</Box>
                    <Box fontWeight="fontWeightRegular">+254725089232</Box>
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  <Typography variant="body2" component="div" gutterBottom>
                    <Box fontWeight="fontWeightMedium">John Doe</Box>
                    <Box fontWeight="fontWeightRegular">+254725089232</Box>
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </RecentCustomersWrapper>
      </CardContent>
    </Card>
  );
};

export default withTheme(RecentCustomers);
