import React, { useEffect } from "react";
import Stats from "./Stats";
import { Grid } from "@material-ui/core";

import { green } from "@material-ui/core/colors";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { getPeriodicSales } from "../../redux/actions/salesActions";

// helpers
import { formatCurrency } from "../../utils/helpers";

const SalesToday = ({ period }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    getPeriodicSales(dispatch, period);
  }, [dispatch, period]);

  const {
    salesTodaySummary: { loading, sales },
  } = useSelector((state) => state.sales);

  return (
    <Grid item xs={12} sm={12} md={6} lg={3} xl>
      <Stats
        title="Sales Today"
        loading={loading}
        data={`${formatCurrency("KES", sales)}`}
        amount="KES 10,000"
        chip="Today"
        percentageText="+26%"
        percentagecolor={green[500]}
      />
    </Grid>
  );
};

export default SalesToday;
