import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components/macro";

import { Helmet } from "react-helmet-async";

// Components
import DeliveryDetails from "../../components/Sale/DeliveryDetails";
import CustomerDetails from "../../components/Sale/CustomerDetails";
import SearchBar from "../../components/Sale/SearchBar";
import SearchResults from "../../components/Sale/SearchResults";
import SelectedProducts from "../../components/Sale/SelectedProducts";
import OrderTotal from "../../components/Sale/OrderTotal";
import OrderSubTotal from "../../components/Sale/OrderSubTotal";
import CompleteOrderButton from "../../components/Sale/CompleteOrderButton";
import SpecialNotes from "../../components/Sale/SpecialNotes";
import SendSMSController from "../../components/Sale/SendSMSController";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateOrderDiscount } from "../../redux/actions/productActions";

import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  List,
  ListItem as MuiListItem,
  Button,
  Link,
  Dialog,
  DialogContent,
  FormControl,
  DialogActions,
  TextField,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

const CardWrapper = styled(Card)`
  margin-bottom: 20px;
`;

const DiscountAmountWrapper = styled.div`
  float: right;
`;

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const ListItemNotButton = styled(MuiListItem)(spacing);

const NewSale = () => {
  const { cartProducts, orderDiscount } = useSelector((state) => state.product);

  let orderTotal = 0;

  cartProducts.forEach(
    (product) =>
      (orderTotal += product.sellingPrice * product.quantity - product.discount)
  );

  const [openOrderDiscount, setOpenOrderDiscount] = useState(false);

  const handleCloseOrderDiscountDialog = () => {
    setOpenOrderDiscount(false);
  };

  const classes = useStyles();

  const dispatch = useDispatch();

  return (
    <React.Fragment>
      <Helmet title="New Sale" />

      <Grid justify="space-between" container spacing={6}>
        <Grid item xs={12} lg={8}>
          <CardWrapper mb={1}>
            <CardContent>
              <SearchBar />
              <SearchResults />
            </CardContent>
          </CardWrapper>

          <CardWrapper mb={1}>
            <CardContent>
              <SelectedProducts />
            </CardContent>
          </CardWrapper>
        </Grid>

        <Grid item xs={12} lg={4}>
          <CardWrapper mb={1}>
            <CardHeader title="Customer Details" />
            <CustomerDetails />
          </CardWrapper>

          <CardWrapper mb={1}>
            <CardContent>
              <List
                component="nav"
                dense={true}
                style={{ marginLeft: "-20px", marginRight: "-20px" }}
              >
                <OrderTotal orderTotal={orderTotal} />
                <ListItemNotButton>
                  <Grid container>
                    <Grid item xs={12} lg={6}>
                      Discount Total Order:
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <DiscountAmountWrapper>
                        <Link
                          href="#"
                          onClick={(event) => {
                            event.preventDefault();
                            setOpenOrderDiscount(true);
                          }}
                        >
                          KES {orderDiscount}
                        </Link>
                      </DiscountAmountWrapper>
                    </Grid>
                  </Grid>
                </ListItemNotButton>

                <OrderSubTotal
                  orderTotal={orderTotal}
                  orderDiscount={orderDiscount}
                />
              </List>
            </CardContent>
          </CardWrapper>

          <Card mb={1}>
            <CardHeader title="Delivery Details" />
            <CardContent>
              <DeliveryDetails />
              <SpecialNotes />
              <SendSMSController />
              <CompleteOrderButton orderTotal={orderTotal} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Grid container spacing={6}>
        <Grid item xs={12}></Grid>
      </Grid>

      <Dialog open={openOrderDiscount} onClose={handleCloseOrderDiscountDialog}>
        <DialogContent>
          <form className={classes.container}>
            <FormControl className={classes.formControl}>
              <TextField
                id="standard-number"
                type="number"
                value={orderDiscount}
                placeholder="Order Discount"
                style={{ width: "100%" }}
                onChange={(event) =>
                  updateOrderDiscount(dispatch, event.target.value)
                }
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{ inputProps: { min: 1 } }}
              />
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseOrderDiscountDialog} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default NewSale;
