import React from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";

import { Helmet } from "react-helmet-async";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography,
  Button,
} from "@material-ui/core";

import { Add as AddIcon } from "@material-ui/icons";

import { spacing } from "@material-ui/system";

// Internal Components
import CustomersTable from "../../components/Customer/CustomersTable";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function Customers() {
  return (
    <React.Fragment>
      <Helmet title="Customers" />

      <Grid justify="space-between" container spacing={24}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Customers
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} exact to="/">
              Dashboard
            </Link>
            <Link component={NavLink} exact to="/">
              Pages
            </Link>
            <Typography>Customers</Typography>
          </Breadcrumbs>
        </Grid>

        <Grid item>
          <Link
            style={{ textDecoration: "none" }}
            component={NavLink}
            to="/customer/new"
            exact
          >
            <Button variant="contained" color="primary">
              <AddIcon />
              New Customer
            </Button>
          </Link>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <CustomersTable />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Customers;
