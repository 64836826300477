import {
  SUBMITTING_ORDER,
  SUBMITTED_ORDER,
  CHANGE_SEND_MESSAGE_STATUS,
} from "../../redux/types/order";

const initialState = {
  loading: false,
  ApiResponse: {},
  sendSMS: false,
};

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUBMITTING_ORDER:
      return {
        ...state,
        loading: true,
      };
    case SUBMITTED_ORDER:
      return {
        ...state,
        loading: false,
        ApiResponse: action.payload,
      };
    case CHANGE_SEND_MESSAGE_STATUS:
      return {
        ...state,
        sendSMS: action.payload,
      };
    default:
      return state;
  }
};

export default orderReducer;
