import axios from "axios";
import { LOADING_SHIPPING_AREAS, SET_SHIPPING_AREAS } from "../types/shipping";

export const getShippingAreas = async (dispatch) => {
  dispatch({
    type: LOADING_SHIPPING_AREAS,
  });

  try {
    const shippingAreas = await axios.get("shipping-areas");

    dispatch({
      type: SET_SHIPPING_AREAS,
      payload: shippingAreas.data.data,
    });
  } catch (error) {
    console.log(error);
  }
};
