import React from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Link,
  Typography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

import { Helmet } from "react-helmet-async";

// Internal Components
import NewCustomerForm from "../../components/Customer/NewCustomerForm";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function NewCustomer() {
  return (
    <React.Fragment>
      <Helmet title="New Customer" />
      <Typography variant="h3" gutterBottom display="inline">
        New Customer
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/dashboard">
          Dashboard
        </Link>
        <Link component={NavLink} exact to="/customers">
          Customers
        </Link>
        <Typography>New</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <NewCustomerForm />
    </React.Fragment>
  );
}

export default NewCustomer;
