import React from "react";
import styled from "styled-components/macro";

import {
  Card as MuiCard,
  CardHeader,
  IconButton,
  Chip as MuiChip,
  Paper as MuiPaper,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";

import { green } from "@material-ui/core/colors";

import { spacing } from "@material-ui/system";

import { MoreVertical } from "react-feather";

const Card = styled(MuiCard)(spacing);

const Chip = styled(MuiChip)`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  background-color: ${(props) => props.rgbcolor};
  color: ${(props) => props.theme.palette.common.white};
`;

const Paper = styled(MuiPaper)(spacing);

const TableWrapper = styled.div`
  height: 380px;
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
`;

// Data
let id = 0;
function createData(name, phone_number, start, end, state, assignee) {
  id += 1;
  return { id, name, phone_number, start, end, state, assignee };
}

const rows = [
  createData(
    "John Doe",
    "+254725089232",
    "KES 20,000",
    <Chip label="Processing" rgbcolor={green[500]} />,
    <Chip label="Postpaid" rgbcolor={green[500]} />,
    "2021-01-02"
  ),
  createData(
    "John Doe",
    "+254725089232",
    "KES 20,000",
    <Chip label="Processing" rgbcolor={green[500]} />,
    <Chip label="Postpaid" rgbcolor={green[500]} />,
    "2021-01-02"
  ),
  createData(
    "John Doe",
    "+254725089232",
    "KES 20,000",
    <Chip label="Processing" rgbcolor={green[500]} />,
    <Chip label="Postpaid" rgbcolor={green[500]} />,
    "2021-01-02"
  ),
  createData(
    "John Doe",
    "+254725089232",
    "KES 20,000",
    <Chip label="Processing" rgbcolor={green[500]} />,
    <Chip label="Postpaid" rgbcolor={green[500]} />,
    "2021-01-02"
  ),
  createData(
    "John Doe",
    "+254725089232",
    "KES 20,000",
    <Chip label="Processing" rgbcolor={green[500]} />,
    <Chip label="Postpaid" rgbcolor={green[500]} />,
    "2021-01-02"
  ),
  createData(
    "John Doe",
    "+254725089232",
    "KES 20,000",
    <Chip label="Processing" rgbcolor={green[500]} />,
    <Chip label="Postpaid" rgbcolor={green[500]} />,
    "2021-01-02"
  ),
];

const RecentSales = () => (
  <Card mb={6}>
    <CardHeader
      action={
        <IconButton aria-label="settings">
          <MoreVertical />
        </IconButton>
      }
      title="Recent Sales"
    />
    <Paper>
      <TableWrapper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Sale Amount</TableCell>
              <TableCell>Shipping Status</TableCell>
              <TableCell>Order Status</TableCell>
              <TableCell>Order Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  <Typography variant="body2" component="div" gutterBottom>
                    <Box fontWeight="fontWeightMedium">{row.name}</Box>
                    <Box fontWeight="fontWeightRegular">{row.phone_number}</Box>
                  </Typography>
                </TableCell>
                <TableCell>{row.start}</TableCell>
                <TableCell>{row.end}</TableCell>
                <TableCell>{row.state}</TableCell>
                <TableCell>{row.assignee}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableWrapper>
    </Paper>
  </Card>
);

export default RecentSales;
