import { combineReducers } from "redux";

import themeReducer from "./themeReducer";
import authReducer from "./authReducer";
import shippingReducer from "./shippingReducer";
import customerReducer from "./customerReducer";
import productReducer from "./productReducer";
import orderReducer from "./orderReducer";
import salesReducer from "./salesReducer";

export const rootReducer = combineReducers({
  themeReducer,
  authReducer,
  shipping: shippingReducer,
  customer: customerReducer,
  product: productReducer,
  order: orderReducer,
  sales: salesReducer,
});
