import React from "react";
import styled from "styled-components/macro";

import { spacing } from "@material-ui/system";

import {
  Table,
  TableContainer,
  Toolbar,
  Paper as MuiPaper,
  Typography,
} from "@material-ui/core";

// Internal Components
import CustomersTableHead from "./CustomersTableHead";
import CustomersTableData from "./CustomersTableData";

const Paper = styled(MuiPaper)(spacing);

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const ToolbarTitle = styled.div`
  min-width: 150px;
`;

const CustomersTable = () => {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("customer");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <div>
      <Paper>
        <Toolbar>
          <ToolbarTitle>
            <Typography variant="h6" id="tableTitle">
              Customers
            </Typography>
          </ToolbarTitle>
          <Spacer />
        </Toolbar>
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <CustomersTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <CustomersTableData />
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

export default CustomersTable;
