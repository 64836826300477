import {
  LOADING_SALES_TODAY,
  SET_SALES_TODAY,
  LOADING_SALES_THIS_MONTH,
  SET_SALES_THIS_MONTH,
  LOADING_SALES_THIS_YEAR,
  SET_SALES_THIS_YEAR,
  LOADING_OVERALL_SALES,
  SET_OVERALL_SALES,
} from "../types/sales";
import axios from "axios";

export const getPeriodicSales = async (dispatch, period) => {
  let loadingType = "";
  let setType = "";

  switch (period) {
    case "today":
      loadingType = LOADING_SALES_TODAY;
      setType = SET_SALES_TODAY;
      break;
    case "month":
      loadingType = LOADING_SALES_THIS_MONTH;
      setType = SET_SALES_THIS_MONTH;
      break;
    case "year":
      loadingType = LOADING_SALES_THIS_YEAR;
      setType = SET_SALES_THIS_YEAR;
      break;
    default:
      loadingType = LOADING_OVERALL_SALES;
      setType = SET_OVERALL_SALES;
  }

  dispatch({
    type: loadingType,
  });

  try {
    const response = await axios.get(`sales/total?period=${period}`);

    dispatch({
      type: setType,
      payload: response.data.data,
    });
  } catch (error) {
    console.log(error);
  }
};
