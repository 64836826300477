import React, { useEffect } from "react";
import { getShippingAreas } from "../../redux/actions/shipping";

// @material-ui/skeleton components
import Skeleton from "@material-ui/lab/Skeleton";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  TextField,
  Typography,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";

import { ExpandMore as ExpandMoreIcon } from "@material-ui/icons";

// Redux
import {
  UPDATE_DELIVERY_ADDRESS,
  SET_SHIPPING_AREA,
} from "../../redux/types/product";

import { SET_SHIPPING_ADDRESS } from "../../redux/types/shipping";
import { useDispatch, useSelector } from "react-redux";

const DeliveryDetails = () => {
  const [expanded, setExpanded] = React.useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    getShippingAreas(dispatch);
  }, [dispatch]);

  const { loading, shippingAreas, addresses, shippingAddress } = useSelector(
    (state) => state.shipping
  );

  const { deliveryAddress, shippingArea } = useSelector(
    (state) => state.product
  );

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return loading ? (
    <>
      <Skeleton animation="wave" />
      <Skeleton />
      <Skeleton animation="wave" />
    </>
  ) : (
    <div>
      {addresses.length > 0 && (
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography style={{ fontSize: "18px" }}>
              Select Shipping Address
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl variant="outlined" m={2} style={{ width: "100%" }}>
              <InputLabel id="demo-simple-select-outlined-label">
                Shipping Address
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="Shipping Area"
                value={shippingAddress || ""}
                onChange={(event) => {
                  dispatch({
                    type: SET_SHIPPING_ADDRESS,
                    payload: parseInt(event.target.value),
                  });
                }}
              >
                {addresses.map((address) => (
                  <MenuItem key={address.id} value={address.id}>
                    {`${address.delivery_address} - ${address.shipping_area.name}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </AccordionDetails>
        </Accordion>
      )}
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={{ fontSize: "18px" }}>
            New Shipping Address
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormControl variant="outlined" m={2} style={{ width: "100%" }}>
            <InputLabel id="demo-simple-select-outlined-label">
              Shipping Area
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              label="Shipping Area"
              value={shippingArea}
              onChange={(event) => {
                dispatch({
                  type: SET_SHIPPING_AREA,
                  payload: event.target.value,
                });
              }}
            >
              {shippingAreas.map((area) => (
                <MenuItem key={area.id} value={area.id}>
                  {area.name}
                </MenuItem>
              ))}
            </Select>

            <TextField
              placeholder="Delivery Address"
              multiline
              rows={3}
              rowsMax={4}
              value={deliveryAddress}
              variant="outlined"
              style={{ marginTop: "20px" }}
              onChange={(event) => {
                dispatch({
                  type: UPDATE_DELIVERY_ADDRESS,
                  payload: event.target.value,
                });
              }}
            />
          </FormControl>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default DeliveryDetails;
