import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";

const headCells = [
  { id: "customer_name", alignment: "left", label: "Customer Name" },
  { id: "phone_number", alignment: "left", label: "Phone Number" },
  { id: "completed_orders", alignment: "right", label: "Completed Orders" },
  { id: "pending_payments", alignment: "left", label: "Pending Payments" },
  { id: "total_sales", alignment: "left", label: "Total Sales" },
];

const CustomersTableHead = (props) => {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default CustomersTableHead;
