import {
  LOADING_PRODUCTS,
  SET_PRODUCTS,
  SEARCH_PRODUCTS,
  ADD_TO_CART,
  REMOVE_FROM_CART,
  UPDATE_CART_QUANTITY,
  UPDATE_PRODUCT_DISCOUNT,
  UPDATE_ORDER_DISCOUNT,
  UPDATE_DELIVERY_ADDRESS,
  SET_SHIPPING_AREA,
  SET_SPECIAL_NOTES,
} from "../types/product";

import { SUBMITTED_ORDER } from "../types/order";

const initialState = {
  loading: false,
  searchTerm: "",
  orderDiscount: "0",
  products: [],
  cartProducts: [],
  deliveryAddress: "",
  shippingArea: "",
  specialNotes: "",
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING_PRODUCTS:
      return {
        ...state,
        loading: true,
      };
    case SEARCH_PRODUCTS:
      return {
        ...state,
        searchTerm: action.payload,
      };
    case SET_PRODUCTS:
      return {
        ...state,
        loading: false,
        products: action.payload,
      };
    case ADD_TO_CART:
      return {
        ...state,
        searchTerm: "",
        products: [],
        cartProducts: [action.payload, ...state.cartProducts],
      };
    case REMOVE_FROM_CART:
      let newCart = state.cartProducts.filter(
        (product) => action.payload.id !== product.id
      );

      return {
        ...state,
        cartProducts: newCart,
      };
    case UPDATE_CART_QUANTITY: {
      let { cartProducts, product, quantity } = action.payload;

      let item = cartProducts.find((item) => item.id === product.id);

      let newCart = cartProducts.filter((item) => item.id !== product.id);

      item.quantity = quantity;

      newCart.push(item);

      return {
        ...state,
        searchTerm: "",
        products: [],
        cartProducts: newCart,
      };
    }
    case UPDATE_PRODUCT_DISCOUNT: {
      let { cartProducts, product, discount } = action.payload;

      let item = cartProducts.find((item) => item.id === product.id);

      let newCart = cartProducts.filter((item) => item.id !== product.id);

      item.discount = discount;

      newCart.push(item);

      return {
        ...state,
        cartProducts: newCart,
      };
    }
    case UPDATE_ORDER_DISCOUNT:
      return {
        ...state,
        orderDiscount: action.payload,
      };
    case UPDATE_DELIVERY_ADDRESS:
      return {
        ...state,
        deliveryAddress: action.payload,
      };
    case SET_SHIPPING_AREA:
      return {
        ...state,
        shippingArea: action.payload,
      };
    case SET_SPECIAL_NOTES:
      return {
        ...state,
        specialNotes: action.payload,
      };
    case SUBMITTED_ORDER:
      return initialState;
    default:
      return state;
  }
};

export default productReducer;
