import React from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";

import { Helmet } from "react-helmet-async";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Checkbox,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Link,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
} from "@material-ui/core";

import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
} from "@material-ui/icons";

import { spacing } from "@material-ui/system";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const ToolbarTitle = styled.div`
  min-width: 150px;
`;

function createData(id, product, date, total, status, method) {
  return { id, product, date, total, status, method };
}

const rows = [
  createData(
    "000253",
    "Vincent Muchiri",
    "Kileleshwa",
    "Nairobi",
    "2021-01-02",
    "KES 20,000",
    "KES 100",
    "KES 0",
    "KES 30,000",
    "KES 30,000"
  ),
];

const headCells = [
  { id: "id", alignment: "right", label: "Order Number" },
  { id: "customer_name", alignment: "left", label: "Customer Name" },
  { id: "shipping_area", alignment: "left", label: "Shipping Area" },
  { id: "county", alignment: "right", label: "County" },
  { id: "order_date", alignment: "left", label: "Date" },
  { id: "subtotal", alignment: "left", label: "Order Subtotal" },
  { id: "shipping_cost", alignment: "left", label: "Shipping Cost" },
  { id: "discount", alignment: "left", label: "Discount" },
  { id: "order_total", alignment: "left", label: "Order Total" },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

let EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar>
      <ToolbarTitle>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            Orders
          </Typography>
        )}
      </ToolbarTitle>
      <Spacer />
      <div>
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete">
              <ArchiveIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Filter list">
            <IconButton aria-label="Filter list">
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </Toolbar>
  );
};

function EnhancedTable() {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("customer");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div>
      <Paper>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              <TableRow
                hover
                role="checkbox"
                aria-checked={false}
                tabIndex={-1}
                key={1}
                selected={false}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={false}
                    inputProps={{ "aria-labelledby": 1 }}
                    onClick={(event) => handleClick(event, 1)}
                  />
                </TableCell>

                <TableCell align="right">#2018364</TableCell>
                <TableCell align="left">Vincent Muchiri</TableCell>
                <TableCell align="left">Kileleshwa</TableCell>
                <TableCell align="right">Nairobi</TableCell>
                <TableCell>2021-01-02</TableCell>
                <TableCell align="left">KES 3,000</TableCell>
                <TableCell align="left">KES 100</TableCell>
                <TableCell align="left">KES 0</TableCell>
                <TableCell align="left">KES 3,000</TableCell>
              </TableRow>
              <TableRow
                hover
                role="checkbox"
                aria-checked={false}
                tabIndex={-1}
                key={2}
                selected={false}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={false}
                    inputProps={{ "aria-labelledby": 1 }}
                    onClick={(event) => handleClick(event, 1)}
                  />
                </TableCell>

                <TableCell align="right">#2018364</TableCell>
                <TableCell align="left">Vincent Muchiri</TableCell>
                <TableCell align="left">Kileleshwa</TableCell>
                <TableCell align="right">Nairobi</TableCell>
                <TableCell>2021-01-02</TableCell>
                <TableCell align="left">KES 3,000</TableCell>
                <TableCell align="left">KES 100</TableCell>
                <TableCell align="left">KES 0</TableCell>
                <TableCell align="left">KES 3,000</TableCell>
              </TableRow>
              <TableRow
                hover
                role="checkbox"
                aria-checked={false}
                tabIndex={-1}
                key={3}
                selected={false}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={false}
                    inputProps={{ "aria-labelledby": 1 }}
                    onClick={(event) => handleClick(event, 1)}
                  />
                </TableCell>

                <TableCell align="right">#2018364</TableCell>
                <TableCell align="left">Vincent Muchiri</TableCell>
                <TableCell align="left">Kileleshwa</TableCell>
                <TableCell align="right">Nairobi</TableCell>
                <TableCell>2021-01-02</TableCell>
                <TableCell align="left">KES 3,000</TableCell>
                <TableCell align="left">KES 100</TableCell>
                <TableCell align="left">KES 0</TableCell>
                <TableCell align="left">KES 3,000</TableCell>
              </TableRow>
              <TableRow
                hover
                role="checkbox"
                aria-checked={false}
                tabIndex={-1}
                key={4}
                selected={false}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={false}
                    inputProps={{ "aria-labelledby": 1 }}
                    onClick={(event) => handleClick(event, 1)}
                  />
                </TableCell>

                <TableCell align="right">#2018364</TableCell>
                <TableCell align="left">Vincent Muchiri</TableCell>
                <TableCell align="left">Kileleshwa</TableCell>
                <TableCell align="right">Nairobi</TableCell>
                <TableCell>2021-01-02</TableCell>
                <TableCell align="left">KES 3,000</TableCell>
                <TableCell align="left">KES 100</TableCell>
                <TableCell align="left">KES 0</TableCell>
                <TableCell align="left">KES 3,000</TableCell>
              </TableRow>
              <TableRow
                hover
                role="checkbox"
                aria-checked={false}
                tabIndex={-1}
                key={5}
                selected={false}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={false}
                    inputProps={{ "aria-labelledby": 1 }}
                    onClick={(event) => handleClick(event, 1)}
                  />
                </TableCell>

                <TableCell align="right">#2018364</TableCell>
                <TableCell align="left">Vincent Muchiri</TableCell>
                <TableCell align="left">Kileleshwa</TableCell>
                <TableCell align="right">Nairobi</TableCell>
                <TableCell>2021-01-02</TableCell>
                <TableCell align="left">KES 3,000</TableCell>
                <TableCell align="left">KES 100</TableCell>
                <TableCell align="left">KES 0</TableCell>
                <TableCell align="left">KES 3,000</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

function OrderList() {
  return (
    <React.Fragment>
      <Helmet title="Orders" />

      <Grid justify="space-between" container spacing={24}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Orders
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} exact to="/">
              Dashboard
            </Link>
            <Link component={NavLink} exact to="/">
              Pages
            </Link>
            <Typography>Orders</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <div>
            <Button variant="contained" color="primary">
              <AddIcon />
              New Order
            </Button>
          </div>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default OrderList;
