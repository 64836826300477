import React from "react";
import styled from "styled-components/macro";

import {
  Box,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Chip as MuiChip,
  Typography as MuiTypography,
} from "@material-ui/core";

// @material-ui/lab components
import Skeleton from "@material-ui/lab/Skeleton";

import { rgba } from "polished";

import { spacing } from "@material-ui/system";

const Card = styled(MuiCard)(spacing);

const Typography = styled(MuiTypography)(spacing);

const CardContent = styled(MuiCardContent)`
  position: relative;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)}px;
  }
`;

const Chip = styled(MuiChip)`
  position: absolute;
  top: 16px;
  right: 16px;
  height: 20px;
  padding: 4px 0;
  font-size: 85%;
  background-color: ${(props) => props.theme.palette.secondary.main};
  color: ${(props) => props.theme.palette.common.white};
  margin-bottom: ${(props) => props.theme.spacing(4)}px;

  span {
    padding-left: ${(props) => props.theme.spacing(2)}px;
    padding-right: ${(props) => props.theme.spacing(2)}px;
  }
`;

const Percentage = styled(MuiTypography)`
  span {
    color: ${(props) => props.percentagecolor};
    font-weight: ${(props) => props.theme.typography.fontWeightBold};
    background: ${(props) => rgba(props.percentagecolor, 0.1)};
    padding: 2px;
    border-radius: 3px;
    margin-right: ${(props) => props.theme.spacing(2)}px;
  }
`;

const Stats = ({
  title,
  amount,
  chip,
  percentageText,
  percentagecolor,
  loading,
  data,
}) => {
  return (
    <Card mb={3}>
      <CardContent>
        {loading ? (
          <>
            <Skeleton />
            <Skeleton animation={false} />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
          </>
        ) : (
          <>
            <Typography variant="h6" mb={4}>
              {title}
            </Typography>
            <Typography variant="h3" mb={3}>
              <Box fontWeight="fontWeightRegular">{data}</Box>
            </Typography>
            <Percentage
              variant="subtitle2"
              mb={4}
              color="textSecondary"
              percentagecolor={percentagecolor}
            >
              <span>{percentageText}</span> Since last week
            </Percentage>
            <Chip label={chip} />
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default Stats;
