import { CardContent, TextField } from "@material-ui/core";
import styled from "styled-components/macro";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { getCustomerByPhoneNumber } from "../../redux/actions/customerActions";
import {
  SET_CUSTOMER_NAME,
  SET_CUSTOMER_PHONE,
} from "../../redux/types/customer";

// Internal Components
// import CustomerSearchResults from "components/Sale/CustomerSearchResults";

const TextFieldWrapper = styled(TextField)`
  margin-bottom: 20px;
`;

const CustomerDetails = () => {
  const dispatch = useDispatch();

  const { phoneNumber, customerDetails } = useSelector(
    (state) => state.customer
  );

  return (
    <CardContent>
      <TextFieldWrapper
        type="number"
        name="phone"
        label="Customer Phone"
        fullWidth
        my={2}
        variant="outlined"
        autoComplete="off"
        value={phoneNumber}
        onChange={(event) =>
          dispatch({
            type: SET_CUSTOMER_PHONE,
            payload: event.target.value,
          })
        }
        onBlur={(event) =>
          getCustomerByPhoneNumber(dispatch, event.target.value)
        }
      />

      <TextField
        type="text"
        name="customerName"
        label="Customer Name"
        fullWidth
        my={2}
        variant="outlined"
        autoComplete="off"
        value={customerDetails.name}
        disabled={customerDetails.id !== 0}
        onChange={(event) =>
          dispatch({
            type: SET_CUSTOMER_NAME,
            payload: event.target.value,
          })
        }
      />
      {/* <CustomerSearchResults /> */}
    </CardContent>
  );
};

export default CustomerDetails;
