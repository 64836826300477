import { TextField, FormControl } from "@material-ui/core";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { SET_SPECIAL_NOTES } from "../../redux/types/product";

const SpecialNotes = () => {
  const { specialNotes } = useSelector((state) => state.product);
  const dispatch = useDispatch();

  return (
    <FormControl variant="outlined" m={2} style={{ width: "100%" }}>
      <TextField
        placeholder="Special Notes"
        multiline
        rows={3}
        rowsMax={4}
        value={specialNotes}
        variant="outlined"
        style={{ marginTop: "20px" }}
        onChange={(event) => {
          dispatch({
            type: SET_SPECIAL_NOTES,
            payload: event.target.value,
          });
        }}
      />
    </FormControl>
  );
};

export default SpecialNotes;
